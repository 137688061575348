<footer>
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <ul>
            <li><a target="_blank" href="https://www.pdfhubonline.com/privacy">Privacy</a></li>
            <li><a target="_blank" href="https://www.pdfhubonline.com/eula">EULA</a></li>
            <li><a target="_blank" href="https://www.pdfhubonline.com/contact">Contact</a></li>
            <li><a target="_blank" href="https://www.pdfhubonline.com/#blog_title">Blog</a></li>
          </ul>
        </div>
        <div class="col-md-6 text-right">
          <p>© PDF Hub, 2015–2022</p>
        </div>
      </div>
    </div>
  </footer>
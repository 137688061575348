<header>
    <div class="container header-main">

        <div class="header-logo">
            <img src="/images/logo.svg" alt="logo" />
            <span>PDF HUB</span>
        </div>
        <div class="text-right">
            <p>Convert files to and from PDF format</p>
        </div>
 
    </div>
</header>